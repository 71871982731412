import React from "react";
import { Layout, Seo } from "components/common";
import { Intro, Skills, Contact, Projects } from "components/landing";
import { Grid } from "../components/landing/Projects/styles"
import Folio from '../components/common/folioLayout/folio'
import "./style.css"
import { StaticImage } from "gatsby-plugin-image"
import { Header } from "components/theme";

export const Blog = () => {
    return (
        <>
            <title> Blog </title>
            <Seo />

            <h1>Blog entries coming soon!</h1>
        </>
    );
};


export default Blog;